.about {
    padding-top: var(--nav-height);
    height: 100%;
    width: 100%;
}

.about-wrapper {
    flex-direction: column;
    /* padding-top: calc(var(--nav-height) + 30px); */
    height: 100%;
    width: 100%;
}

.about-card {
    /* width: 50%; */
    height: 300px;
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.about-card-image-wrapper {
    height: 300px;
    aspect-ratio: 3670/5496;
    /* width: 100%; */
    background-position: contain;
}

.about-image {
    height: 300px;
    background-position: contain;
}

.description-card {
    width: 300px;
    padding: 8px;
}

.description-card-text>h4 {
    padding: 8px;
}

@media screen and (min-width: 600px) {
    .about-wrapper {
        flex-direction: row;
        height: calc(100% - 128px);
        width: 100%;
    }
}