.flexc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexs {
    display: flex;
    justify-content: center;
    align-items: center;
}