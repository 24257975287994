.home {
    flex-direction: column;
}

#home-background {
    position: absolute;
    background-position: cover;
    height: 200vh;
    width: 100%;
    animation: spin-spiral 3s infinite linear;
}

@keyframes spin-spiral {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.hero {
    height: calc(100vh - var(--nav-height));
    width: 100%;
    z-index: 1;
    aspect-ratio: var(--portrait-aspect-ratio);
}

.hero>video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
}

.album-break {
    height: var(--nav-height);
}

.album-link {
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100%;
    height: 100%;
    aspect-ratio: var(--portrait-aspect-ratio);
    user-select: none;
}

.parallax-banner {
    width: 100%;
    height: 100%;
    aspect-ratio: var(--portrait-aspect-ratio);
}

@media screen and (min-width: 600px) {
    .hero {
        aspect-ratio: var(--landscape-aspect-ratio);
    }

    .album-link {
        aspect-ratio: var(--landscape-aspect-ratio);
        align-items: center;
        justify-content: start;
        /* background: linear-gradient(to right, rgba(255,255,255,0.8) 50%, transparent); */
        /* background-size: 300% 300%;
        background-position: 50% 0; */
        transition: .6s;
    }

    .album-link-left {
        background: linear-gradient(to right, rgba(255,255,255,0.8) 50%, transparent);
        background-size: 300% 300%;
        background-position: 50% 0;
    }

    .album-link-right {
        justify-content: end;
        background: linear-gradient(to left, rgba(255,255,255,0.8) 50%, transparent);
        background-size: 300% 300%;
        background-position: 50% 0;
    }

    .album-link>h1 {
        transition: .6s;
        padding: 1em;
    }

    /* .album-link:hover {
        background-position: 100% 0;
    } */
    
    .album-link-left:hover {
        background-position: 100% 0;
    }

    .album-link-right:hover {
        background-position: 0% 0;
    }
    
    .album-link:hover>h1 {
        transition: 0.6s;
        color: rgba(0,0,0,0.3);
    }

    .parallax-banner {
        aspect-ratio: var(--landscape-aspect-ratio);
    }
}
