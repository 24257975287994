.album-pieces {
    /* padding-top: calc(var(--nav-height) + 3em); */
    height: calc(100% - var(--nav-height) - 1em - 8px);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2fr);
    overflow-y: auto;
}

.album-piece-wrapper {
    grid-row: span 1;
    grid-column: span 1;
    height: 600px;
    width: 100%;
}

.album-piece {
    height: calc(100% - 8px);
    width: calc(100% - 8px);
    background-size: cover !important;
    background-position: center;
    margin: 4px;
    background-repeat: no-repeat;
}

.album-title {
    margin-top: var(--nav-height);
    padding: 1em;
    font-size: 2em;
    font-weight: bold;
}

@media screen and (min-width: 600px) {
    .album-pieces {
        grid-template-columns: repeat(2, 1fr);
    }
}