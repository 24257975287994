body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root, .gallery-theme {
  --primary-text-color: var(--black);
  --primary-background-color: var(--white);
  --ui-primary-background-color: rgba(255,255,255,0.3);
}

/* :root, .fun-theme {
  --primary-text-color: purple;
  --primary-background-color: black;
} */

:root {
  --font-family: "roboto";
  --h1-font-size: 3em;
  --h2-font-size: 2.5em;
  --h3-font-size: 2em;
  --h4-font-size: 1.5em;
  --h5-font-size: 1em;

  --icon-large: 3em;
  --icon-medium: 2em;
  --icon-small: 1em;

  --nav-height: 4rem;

  --li-side-padding: 0.2em;

  --landscape-aspect-ratio: 16 / 9;
  --portrait-aspect-ratio: 9 / 14;

  --scrollbar-width: 8px;
  --scrollbar-transition: 0.3s;
  --scrollbar-track-color: transparent;
  --scrollbar-thumb-color: rgb(0, 0, 0, 0.7);
  --scrollbar-thumb-color-hover: rgba(0, 0, 0, 1);

  --transition-speed: 0.3s;

  --black: rgb(0, 0, 0);
  --white: rgb(255, 255, 255);
}
